import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <>
      <html>
        <head>
          <meta charSet="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Dynamic Iframe URL Example</title>
          <style>
            {`
              /* Style to make the iframe fill the entire viewport */
              html,
              body,
              #iframeContainer,
              iframe {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
              }

              /* Style for the image */
              #outputImage {
                display: block;
                width: 1024px;
                height: 1024px;
                margin-top: 20px;
              }
            `}
          </style>
        </head>
        <body>
          <div id="iframeContainer"></div>
          <img id="outputImage" alt="Output Image" />
        </body>
      </html>
    </>
  );
}

export default App;
